import React, { useState } from 'react'
import Seo from '@widgets/Seo'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import { Box } from 'theme-ui'
import { Layout, Stack, Main, PreFooter, Hero } from '@layout'
import { attachExcerptToEvent, getStaticPageSeoDetails } from '@utils-blog'
import Divider from '@components/Divider'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const styles = {
  boxWrap: {
    mb: 20,
    display: 'flex',
    gap: "8px",
    flexWrap: "wrap",
    '> div': {
      mr: 3,
      cursor: 'pointer'
    }
  }
}
const Video = props => {
  let video = props.data.paginated
  let allvideo = props.data.allVideo
  const videoCategories = props.data.categories.nodes
  let [filterData, setFilterData] = useState()
  let [selectedFilter, setSelectedFilter] = useState()
  // attachCategoriesToEvent(video)
  // video.nodes.forEach(ele => {
  //   ele.category = ele.categories[0].name
  // })
  attachExcerptToEvent(video)

  let categoryList = videoCategories && videoCategories.map(category => category.categories?.map(name => name?.name)).flat()
  categoryList = categoryList.filter(el => el !== undefined)
  categoryList = categoryList.filter((item, index) => categoryList.indexOf(item) === index)
  const handleVideoFilter = (selectedCategory, i) => {
    // console.log(i, 'index=');
    setSelectedFilter(i)
    if (selectedCategory === 'Show All') {
      setFilterData()
    } else {
      let arr = []
      videoCategories.map(t => {
        if (t.categories.some(category => category.name === selectedCategory)) {
          arr.push(allvideo.nodes.find(v => t.title === v.title))
          setFilterData(arr)
        }
      })
    }
  }

  let seoData = getStaticPageSeoDetails('shared.videos')

  const videoSeoData = {
    ...seoData,
    Videos: {
      ...seoData?.Videos,
      keywords: seoData?.Videos?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={videoSeoData?.Videos?.metaTitle}
        description={videoSeoData?.Videos?.metaDescription}
        keywords={videoSeoData?.Videos?.keywords}
        thumbnail={videoSeoData?.Videos?.metaImage}

      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'video'}
            {...props}
            callFrom='Heritage Series'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Box sx={styles.boxWrap}>
            <Box
              style={selectedFilter === 'showAll' ? { fontWeight: 600 } : {}}
              onClick={() => handleVideoFilter('Show All', 'showAll')}
            >
              Show All
            </Box>
            {categoryList && categoryList.map((category, i) => {
              return (
                <Box
                  style={selectedFilter === i ? { fontWeight: 600 } : {}}
                  onClick={() => handleVideoFilter(category, i)}
                >
                  {category}
                </Box>
              )
            })}
          </Box>
          <CardList
            nodes={filterData ? filterData : video.nodes}
            limit={30}
            skip={0}
            callFrom='Video'
            columns={[1, 2, 3]}
            variant={['horizontal-md', 'vertical']}
          />
          <Divider></Divider>
        </Main>
      </Stack>
      <PreFooter>
        <Pagination {...video.pageInfo} prefix={'videos'} />
      </PreFooter>
    </Layout>
  )
}

export default Video
